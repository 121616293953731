html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
}

.file-uploader{
  display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 8px;
gap: 16px;
max-width: 608px !important;
height: 120px !important;
background: rgba(95, 99, 104, 0.05);
border: 2px dashed #E5E8EA !important;
margin-bottom: 1.5rem;
border-radius: 6px;
}

.word-break-word{
  word-break: break-word;
}