/* input styles */
.date-picker.rc-picker-focused {
  @apply border-0;
}

.date-picker.rc-picker {
  @apply w-full;
}

.date-picker.rc-picker > .rc-picker-input > input {
  @apply box-border;
  @apply w-full;
  @apply rounded-md;
  @apply border-2;
  @apply border-transparent;
  @apply bg-tint-dark-10;
  @apply px-4;
  @apply text-neutral-90;
  @apply caret-primary-turquoise-70;
  @apply transition-colors;
  @apply placeholder:text-base;
  @apply placeholder:text-tint-dark-50;
  @apply focus:border-primary-turquoise-30;
  @apply focus:outline-none;
  @apply disabled:cursor-not-allowed;
  @apply disabled:text-tint-dark-50;
  @apply h-12;
  @apply pr-9;
}

/* change the colours slightly for the edit workshop header */
.date-picker-light.rc-picker > .rc-picker-input > input {
  @apply bg-black;
  @apply bg-opacity-15;
  @apply text-white;
  @apply placeholder:text-white;
  @apply placeholder:text-opacity-30;
}

.date-picker.rc-picker.error > .rc-picker-input > input {
  @apply border-secondary-red-50;
  @apply caret-secondary-red-50;
  @apply focus:border-secondary-red-50;
}

/* container styles */
.rc-picker-dropdown {
  @apply z-50;
  @apply shadow-picker;
  @apply text-white;
}

.rc-picker-dropdown .rc-picker-panel {
  @apply bg-neutral-90;
  @apply rounded-lg;
  @apply border-none;
  @apply p-4;
}

.rc-picker-panel .rc-picker-header {
  @apply flex;
  @apply justify-around;
  @apply items-start;
  @apply text-xl;
  @apply mb-2;
}

.rc-picker-panel .rc-picker-header .rc-picker-header-view {
  @apply pt-0.5;
  @apply flex;
  @apply gap-2;
  @apply justify-center;
}

.rc-picker-panel .rc-picker-header .rc-picker-header-prev-btn {
  @apply h-6;
  @apply w-8;
  @apply ml-4;
}

.rc-picker-panel .rc-picker-header .rc-picker-header-next-btn {
  @apply h-6;
  @apply w-8;
  @apply mr-4;
}

.rc-picker-header .rc-picker-header-super-prev-btn,
.rc-picker-header .rc-picker-header-super-next-btn {
  @apply hidden;
}

.rc-picker-panel .rc-picker-body {
}

.rc-picker-panel .rc-picker-body .rc-picker-week-panel-row {
  @apply align-middle;
}

.rc-picker-panel .rc-picker-body .rc-picker-cell {
  @apply text-white;
  @apply opacity-50;
}

.rc-picker-panel .rc-picker-body .rc-picker-cell .rc-picker-cell-inner {
  @apply rounded-3xl;
  @apply text-sm;
  @apply leading-10;
  @apply text-center;
  @apply h-10;
  @apply w-10;
}

.rc-picker-panel .rc-picker-body .rc-picker-cell.rc-picker-cell-in-view {
  @apply opacity-100;
}

.rc-picker-panel .rc-picker-body .rc-picker-cell .rc-picker-cell-inner:hover {
  @apply bg-primary-turquoise-10;
  @apply text-neutral-90;
}

.rc-picker-panel .rc-picker-body .rc-picker-cell.rc-picker-cell-selected {
  @apply rounded-3xl;
  @apply bg-primary-turquoise-10;
}

.rc-picker-panel
  .rc-picker-body
  .rc-picker-cell.rc-picker-cell-selected
  .rc-picker-cell-inner {
  @apply bg-primary-turquoise-10;
}

.rc-picker-panel
  .rc-picker-body
  .rc-picker-cell.rc-picker-cell-selected.rc-picker-cell-in-view {
  @apply text-neutral-90;
}

.rc-picker-panel
  .rc-picker-body
  .rc-picker-cell.rc-picker-cell-today
  .rc-picker-cell-inner {
  @apply border-none;
  @apply outline;
  @apply outline-2;
  @apply outline-white;
}

/* week select */
.rc-picker-panel .rc-picker-body .rc-picker-week-panel-row td:nth-child(2) {
  @apply rounded-tl-3xl;
  @apply rounded-bl-3xl;
}
.rc-picker-panel .rc-picker-body .rc-picker-week-panel-row td:last-child {
  @apply rounded-tr-3xl;
  @apply rounded-br-3xl;
}

.rc-picker-week-panel-row:hover .rc-picker-cell {
  @apply bg-primary-turquoise-10/20;
  @apply text-neutral-90;
}

.rc-picker-week-panel-row.rc-picker-week-panel-row-selected td {
  @apply bg-primary-turquoise-10/20;
}

.rc-picker-week-panel-row.rc-picker-week-panel-row-selected
  td:nth-child(n + 3):nth-child(-n + 7) {
  @apply bg-primary-turquoise-10;
  @apply text-neutral-90;
}

.rc-picker-panel
  .rc-picker-body
  .rc-picker-week-panel-row.rc-picker-week-panel-row-selected
  .rc-picker-cell.rc-picker-cell-selected {
  @apply rounded-none;
}

.rc-picker-week-panel-row.rc-picker-week-panel-row-selected
  .rc-picker-cell-in-view,
.rc-picker-week-panel-row.rc-picker-week-panel-row-selected .rc-picker-cell {
  @apply !border-none;
  @apply !p-0;
}

.rc-picker-week-panel-row.rc-picker-week-panel-row-selected
  .rc-picker-cell:nth-last-child(2),
.rc-picker-week-panel-row.rc-picker-week-panel-row-selected
  .rc-picker-cell-in-view:nth-last-child(2) {
  @apply !bg-primary-turquoise-10/20;
  @apply !border-none;
  @apply !p-0;
}

.rc-picker-week-panel-row.rc-picker-week-panel-row-selected
  .rc-picker-cell-in-view:not(:last-child)
  .rc-picker-cell-inner {
  @apply !bg-primary-turquoise-10;
  @apply !w-full;
}

.rc-picker-week-panel-row.rc-picker-week-panel-row-selected
  td:nth-child(3).rc-picker-cell-in-view
  .rc-picker-cell-inner,
.rc-picker-week-panel-row.rc-picker-week-panel-row-selected
  td:nth-child(7).rc-picker-cell-in-view
  .rc-picker-cell-inner {
  @apply !rounded-3xl;
}

.rc-picker-week-panel-row.rc-picker-week-panel-row-selected
  td:nth-child(3).rc-picker-cell-in-view {
  background: linear-gradient(90deg, #3e5a5b 50%, #58ebd2 50%);
}
.rc-picker-week-panel-row.rc-picker-week-panel-row-selected
  td:nth-child(3).rc-picker-cell {
  @apply !bg-primary-turquoise-10;
  background: linear-gradient(90deg, #3e5a5b 50%, #58ebd2 50%);
}
.rc-picker-week-panel-row.rc-picker-week-panel-row-selected
  td:nth-child(3).rc-picker-cell
  .rc-picker-cell-inner {
  @apply !bg-primary-turquoise-10;
}

.rc-picker-week-panel-row.rc-picker-week-panel-row-selected
  td:nth-child(7).rc-picker-cell
  .rc-picker-cell-inner {
  @apply !rounded-3xl;
}

.rc-picker-week-panel-row.rc-picker-week-panel-row-selected
  td:nth-child(7).rc-picker-cell-in-view,
.rc-picker-week-panel-row.rc-picker-week-panel-row-selected
  td:nth-child(7).rc-picker-cell {
  background: linear-gradient(90deg, #58ebd2 50%, #3e5a5b 50%);
}

.rc-picker-week-panel-row.rc-picker-week-panel-row-selected
  .rc-picker-cell-in-view:nth-last-child(2)
  .rc-picker-cell-inner,
.rc-picker-week-panel-row.rc-picker-week-panel-row-selected
  .rc-picker-cell:nth-last-child(2)
  .rc-picker-cell-inner {
  @apply !bg-primary-turquoise-10;
  @apply !rounded-tr-3xl;
  @apply !rounded-br-3xl;
  @apply !rounded-l-none;
  @apply !w-full;
}

.rc-picker-week-panel-row.rc-picker-week-panel-row-selected
  .rc-picker-cell:nth-child(2) {
  @apply !bg-primary-turquoise-10/20;
  @apply !rounded-tl-3xl;
  @apply !rounded-bl-3xl;
  @apply !rounded-r-none;
  @apply !text-white;
}

.rc-picker-week-panel-row.rc-picker-week-panel-row-selected
  .rc-picker-cell:nth-child(2)
  .rc-picker-cell-inner,
.rc-picker-week-panel-row.rc-picker-week-panel-row-selected
  td:nth-child(2).rc-picker-cell-end
  .rc-picker-cell-inner {
  @apply !bg-transparent;
}

.rc-picker-week-panel-row.rc-picker-week-panel-row-selected
  td:nth-child(2).rc-picker-cell-end {
  @apply !bg-primary-turquoise-10/40;
}
.rc-picker-week-panel-row.rc-picker-week-panel-row-selected
  td:nth-child(2).rc-picker-cell-end.rc-picker-cell-in-view {
  @apply !bg-primary-turquoise-10/10;
}

/* hide week display at container */
.rc-picker-week-panel table.rc-picker-content thead th:first-of-type {
  @apply hidden;
}

.rc-picker-panel .rc-picker-body {
}

.rc-picker-panel .rc-picker-body .rc-picker-week-panel-row {
  @apply align-middle;
}

.rc-picker-panel .rc-picker-body .rc-picker-cell {
  @apply text-white;
  @apply opacity-50;
}

.rc-picker-panel .rc-picker-body .rc-picker-cell .rc-picker-cell-inner {
  @apply rounded-3xl;
  @apply text-sm;
  @apply leading-10;
  @apply text-center;
  @apply h-10;
  @apply w-10;
}

.rc-picker-panel .rc-picker-body .rc-picker-cell.rc-picker-cell-in-view {
  @apply opacity-100;
}

.rc-picker-panel .rc-picker-body .rc-picker-cell .rc-picker-cell-inner:hover {
  @apply bg-primary-turquoise-10;
  @apply text-neutral-90;
}

.rc-picker-panel .rc-picker-body .rc-picker-cell.rc-picker-cell-selected {
  @apply rounded-3xl;
  @apply bg-primary-turquoise-10;
}

.rc-picker-panel
  .rc-picker-body
  .rc-picker-cell.rc-picker-cell-selected
  .rc-picker-cell-inner {
  @apply bg-primary-turquoise-10;
}

.rc-picker-panel
  .rc-picker-body
  .rc-picker-cell.rc-picker-cell-selected.rc-picker-cell-in-view {
  @apply text-neutral-90;
}

.rc-picker-panel
  .rc-picker-body
  .rc-picker-cell.rc-picker-cell-today
  .rc-picker-cell-inner {
  @apply border-none;
  @apply outline;
  @apply outline-2;
  @apply outline-white;
  @apply outline-offset-[-2px];
}

/* week select */
.rc-picker-panel .rc-picker-body .rc-picker-week-panel-row td:nth-child(2) {
  @apply rounded-tl-3xl;
  @apply rounded-bl-3xl;
}
.rc-picker-panel .rc-picker-body .rc-picker-week-panel-row td:last-child {
  @apply rounded-tr-3xl;
  @apply rounded-br-3xl;
}

.rc-picker-week-panel-row:hover .rc-picker-cell {
  @apply bg-primary-turquoise-10/20;
  @apply text-neutral-90;
}

.rc-picker-week-panel-row.rc-picker-week-panel-row-selected td {
  @apply bg-primary-turquoise-10/20;
}

.rc-picker-week-panel-row.rc-picker-week-panel-row-selected
  td:nth-child(n + 3):nth-child(-n + 7) {
  @apply bg-primary-turquoise-10;
  @apply text-neutral-90;
}

.rc-picker-panel
  .rc-picker-body
  .rc-picker-week-panel-row.rc-picker-week-panel-row-selected
  .rc-picker-cell.rc-picker-cell-selected {
  @apply rounded-none;
}

/* hide week display at container */
.rc-picker-week-panel .rc-picker-content > table > thead:first-child {
  @apply hidden;
}

.rc-picker-week-panel .rc-picker-cell.rc-picker-cell-week {
  @apply hidden;
}

.rc-picker-week-panel .rc-picker-cell.rc-picker-cell-week {
  @apply hidden;
}

/* Disabled Cell */
.rc-picker-panel
  .rc-picker-body
  .rc-picker-cell-disabled
  .rc-picker-cell-inner {
  @apply text-neutral-60;
}

/* Disabled Cell Inner */
.rc-picker-panel
  .rc-picker-body
  .rc-picker-cell-disabled
  .rc-picker-cell-inner:hover {
  @apply text-neutral-60;
  @apply bg-transparent;
  @apply cursor-default;
}
