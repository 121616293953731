.circle {
    position: relative;
    border: 1px solid rgba(229, 232, 234, 1);
    padding: 0;
    width: 25em;
    height: 25em;
    margin-left: 8rem;
    border-radius: 50%;
    list-style: none;
    overflow: hidden;
    
  }
 

    
  .same li {
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 50%;
    transform-origin: 0% 100%;
    border: 1px solid rgba(229, 232, 234, 1);
    font-size: 24.08px;
    
  }

  .D {
    position: relative;
    top: 200px;
    left: 100px;
    color: rgba(15, 178, 86, 1);
  }
  .I{
    position: relative;
    top: 184px;
    left: 540px;
    color: rgba(227, 75, 75, 1);
  }
  .S{
    position: relative;
    top: 505px;
    left: 540px;
    color: rgba(44, 139, 192, 1);
  }
  .C {
    position: relative;
    top: 495px;
    left: 100px;
    color: rgba(235, 132, 36, 1);
  }

  .D,.I,.S,.C{
    font-size: 24.08px;
    height: 26px;
    width: 17px;
    font-weight: 500;
  }

  .Bold{
    position: relative;
    top: 32px;
    left: 315px;
  }
  .People{
    position: relative;
    top: 230px;
    left: 533px;
  }
  .Calm {
    position: relative;
    top: 436px;
    left: 318px;
  }
  .Task {
    position: relative;
    top: 208px;
    left: 98px;
  }
    .Bold,.People,.Calm,.Task{
      font-weight: 400;
    }
  .text {
    position: absolute;
    left: -100%;
    width: 200%;
    height: 200%;
    text-align: center;
    transform: skewY(60deg) rotate(15deg);
    padding-top: 20px;
    font-weight: 900;
  }
  .same li:first-child {
    transform: rotate(0deg) skewY(-60deg);
  }
  .same li:nth-child(2) {
    transform: rotate(30deg) skewY(-60deg);
  }
  .same li:nth-child(3) {
    transform: rotate(60deg) skewY(-60deg);
  }
  .same li:nth-child(4) {
    transform: rotate(90deg) skewY(-60deg);
  }
  .same li:nth-child(5) {
    transform: rotate(120deg) skewY(-60deg);
  }
  .same li:nth-child(6) {
    transform: rotate(150deg) skewY(-60deg);
  }
  .same li:nth-child(7) {
    transform: rotate(180deg) skewY(-60deg);
  }
  .same li:nth-child(8) {
    transform: rotate(210deg) skewY(-60deg);
  }
  .same li:nth-child(9) {
    transform: rotate(240deg) skewY(-60deg);
  }
  .same li:nth-child(10) {
    transform: rotate(270deg) skewY(-60deg);
  }
  .same li:nth-child(11) {
    transform: rotate(300deg) skewY(-60deg);
  }
  .same li:nth-child(12) {
    transform: rotate(330deg) skewY(-60deg);
  }
  .same li:first-child .text {
    background: white;
  }
  .same li:nth-child(2) .text {
    background: white;
  }
  .same li:nth-child(3) .text {
    background: white;
  }
  .same li:nth-child(4) .text {
    background: rgba(44, 139, 192, 0.1);
  }
  .same li:nth-child(4) .text:before{
    position: absolute;
    top: 15%; /* position the text element at the top of the circle */
    left: 50%; /* center the text element horizontally */
    transform: translate(-50%, -50%);
    font-size: 24.08px;
    font-weight: 500;
    color: rgba(44, 139, 192, 1);
  } 
  .same li:nth-child(5) .text {
    background: white;
  }
  .same li:nth-child(6) .text {
    background: white;
  }
  .same li:nth-child(7) .text {
    background: white;
  }
  .same li:nth-child(8) .text {
    background: rgba(235, 132, 36, 0.1);
  }
  .same li:nth-child(8) .text:before{
    position: absolute;
    top: 15%; /* position the text element at the top of the circle */
    left: 50%; /* center the text element horizontally */
    transform: translate(-50%, -50%);
    font-size: 24.08px;
    font-weight: 500;
    color: rgba(235, 132, 36, 1);
  } 
  .same li:nth-child(9) .text {
    background: rgba(255, 194, 138, 1);
  }
  .same li:nth-child(9) .text:before{
    position: absolute;
    top: 15%; /* position the text element at the top of the circle */
    left: 50%; /* center the text element horizontally */
    transform: translate(-50%, -50%);
    color: rgba(235, 132, 36, 1);
    font-size: 24.08px;
    font-weight: 500;
  } 
  .same li:nth-child(10) .text {
    background: white;
  }
  li:nth-child(11) .text {
    background: rgba(15, 178, 86, 0.1);
  }
  li:nth-child(11) .text:before{
    position: absolute;
    top: 15%; /* position the text element at the top of the circle */
    left: 50%; /* center the text element horizontally */
    transform: translate(-50%, -50%);
    color: rgba(15, 178, 86, 1);
    font-size: 24.08px;
    font-weight: 500;
  } 
  li:nth-child(12) .text {
    background: white;
  }