.test{
    display: inline-block;
    width:60px;
    height: 60px;
    background: gray;
    line-height: 10px;
    border-radius: 30px;
    overflow: hidden;
}

.orangepie-4 > div {
    display: inline-block;
    width: 30px;
    height: 30px;
   
  }
  .orangepie-4 > div:nth-child(1) {
    background: #fff;
  }
  .orangepie-4 > div:nth-child(2) {
    background: #fff;
  }
  .orangepie-4 > div:nth-child(3) {
    background: #EB8424;
    
  }
  .orangepie-4 > div:nth-child(4){
    background: white;
  } 
  