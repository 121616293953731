.project-gradient-bg {
  background: radial-gradient(
      187.63% 187.63% at 50% 183%,
      theme("colors.gradients.bazaar") 0%,
      theme("colors.neutral.5") 78.65%
    ),
    theme("colors.neutral.0");
}

.temp-check-gradient-bg {
  background: linear-gradient(180deg, theme("colors.gradients.tempCheckFrom"), theme("colors.gradients.tempCheckTo"));
}
.pulse-check-average-gradient-bg {
  background: linear-gradient(90deg, theme("colors.gradients.pulseCheckAverageFrom") 0%, theme("colors.gradients.pulseCheckAverageTo") 100%);
}