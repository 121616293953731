.test{
    display: inline-block;
    width:60px;
    height: 60px;
    background: gray;
    line-height: 10px;
    border-radius: 30px;
    overflow: hidden;
}

.greenpie-4 > div {
    display: inline-block;
    width: 30px;
    height: 30px;
   
  }
  .greenpie-4 > div:nth-child(1) {
    background: #0FB256;
  }
  .greenpie-4 > div:nth-child(2) {
    background: #fff;
  }
  .greenpie-4 > div:nth-child(3) {
    background: #ffff;
  }
  .greenpie-4 > div:nth-child(4){
    background: #fff;
  } 
  