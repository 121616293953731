/* Regular */
@font-face {
  font-family: "Steradian";
  src: url(../assets/fonts/Steradian-Rg.otf) format("opentype");
  font-weight: 400;
}

/* Regular italic */
@font-face {
  font-family: "Steradian";
  src: url(../assets/fonts/Steradian-RgIt.otf) format("opentype");
  font-weight: 400;
  font-style: italic;
}

/* Medium */
@font-face {
  font-family: "Steradian";
  src: url(../assets/fonts/Steradian-Md.otf) format("opentype");
  font-weight: 500 900;
}

/* Medium italic */
@font-face {
  font-family: "Steradian";
  src: url(../assets/fonts/Steradian-MdIt.otf) format("opentype");
  font-weight: 500 900;
  font-style: italic;
}

/* Light */
@font-face {
  font-family: "Steradian";
  src: url(../assets/fonts/Steradian-Lt.otf) format("opentype");
  font-weight: 300;
}

/* Light italic */
@font-face {
  font-family: "Steradian";
  src: url(../assets/fonts/Steradian-LtIt.otf) format("opentype");
  font-weight: 300;
  font-style: italic;
}

/* Extra light */
@font-face {
  font-family: "Steradian";
  src: url(../assets/fonts/Steradian-XLt.otf) format("opentype");
  font-weight: 100 200;
}

/* Extra light */
@font-face {
  font-family: "Steradian";
  src: url(../assets/fonts/Steradian-XLtIt.otf) format("opentype");
  font-weight: 100 200;
  font-style: italic;
}
