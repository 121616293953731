.test{
    display: inline-block;
    width:60px;
    height: 60px;
    background: gray;
    line-height: 10px;
    border-radius: 30px;
    overflow: hidden;
}

.bluepie-4 > div {
    display: inline-block;
    width: 30px;
    height: 30px;
   
  }
  .bluepie-4 > div:nth-child(1) {
    background: #fff;
  }
  .bluepie-4 > div:nth-child(2) {
    background: #fff;
  }
  .bluepie-4 > div:nth-child(3) {
    background: #fff;
  }
  .bluepie-4 > div:nth-child(4){
    background: #2C8BC0;
  } 
  