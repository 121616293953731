@import "fonts.css";
@import "gradients.css";
@import "main.css";
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer utilities {
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }
    .no-scrollbar {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    ::-webkit-scrollbar{
        overflow: hidden;
      }

    /* Overwrite the default to keep the scrollbar always visible for comments */
    .comments-scrollbar::-webkit-scrollbar {
      -webkit-appearance: none;
      border-radius: 8px;
      width: 3px;
      background-color: #d4d5d7;
    }

    .comments-scrollbar::-moz-scrollbar {
      -moz-appearance: none;
      border-radius: 8px;
      width: 3px;
      background-color: #d4d5d7;
    }

    .comments-scrollbar::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: #292D30;
      border-radius: 8px;
    }

    .comments-scrollbar::-moz-scrollbar-thumb {
      border-radius: 8px;
      background-color: #292D30;
      border-radius: 8px;
    }

    .para-w-stretch-space {
      width: 100%;
      width: -moz-available;          /* WebKit-based browsers will ignore this. */
      width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
      width: stretch;
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        @apply appearance-none;
    }
}